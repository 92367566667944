.project_list {
  align-items: center;
  width: 100%;
}

.project_cards {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.3rem;
  padding: 3px;
  justify-content: center;
  margin-top: 30px;
  /* justify-content: space-between; */
}
.last__project__card {
  width: 20rem;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 3px 3px rgb(185, 179, 179);
  box-shadow: 0 1.5em 2.5em -0.5em rgba(#000000, 0.1);
  transition: transform 0.45s ease, 0.45s ease;
  display: flex;
  border: 2px solid gray;
  align-items: center;
}
.last__project__card:hover {
  cursor: pointer;
  transform: translate(-10px, -10px);
}
@media (max-width: 480px) {
  .projectList__bottom {
    display: none;
  }
}
