.main_contact_div {
  color: aliceblue;
  position: relative;
}

.contact_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 5%;
  /* height: 100%; */
  width: 100%;
  z-index: 1;
  /* flex-wrap: wrap; */
  /* border: 2px solid green; */
}
.lottie_container {
  height: 450px;
  width: 450px;
  border-radius: 10px solid #de535f;
  padding: 10px;
  position: relative;
  left: 5%;
}
.email_details_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10%;
  margin-top: 2%;
  gap: 1rem;
  /* border: 1px solid red; */
}
.email_details_container h1 {
  color: #42a5f5;
}
#form_material {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 10px;
  width: 55ch;
  /* border:5px,solid,#42a5f5 */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form_material_textField {
  width: 45ch;
  /* border:2px solid #3b698e !important; */
}
.form_material_textArea {
  width: 52ch;
  background-color: rgba(255, 255, 255, 0.15) !important;
}
.send_me_Button {
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  border: none;
  color: white;
  font-weight: 700;
  letter-spacing: 0.2rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 20px;
  background: #104451; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #4ca2b6,
    #092a32
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #348192,
    #19687b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.send_me_Button:hover {
  background: #2193b0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #6dd5ed,
    #2193b0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #6dd5ed,
    #2193b0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}

.contact__bottom__nav {
  margin-top: 5%;
  position: sticky;
  bottom: 0;
}
.contact__top__nav {
  /* z-index: 999; */
  /* margin-bottom: 5px;
  height: 90px; */
  position: relative;
  z-index: 2;
}
@media (max-width: 480px) {
  /* .contact__top__nav {
    min-height: 4.5rem;
    margin-bottom: 5px;
  } */
  .contact__bottom__nav {
    display: none;
  }
  .contact_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .lottie_container {
    height: 225px;
    width: 250px;
    /* border-radius: 10px solid #de535f; */
    padding: 10px;
    position: relative;
    top: 20px;
    overflow: hidden;
  }
  .email_details_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  .form_material_textField {
    width: 80%;
    /* border: 2px solid #3b698e !important; */
  }
  .form_material_textArea {
    width: 80%;
    background-color: rgba(255, 255, 255, 0.15) !important;
  }

  #form_material {
    background-color: rgba(255, 255, 255, 0.85);
    width: 80%;
    padding: 5px;
    /* border:5px,solid,#42a5f5 */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .send_me_Button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    color: white;
    font-weight: 700;
    letter-spacing: 0.2rem;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 30px;
  }
}
