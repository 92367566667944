.topNav_div {
  /* border: 1px solid red; */
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  gap: 2rem;
  z-index: 2;
  /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}
.topNav__div__black {
  background-color: rgba(255, 255, 255, 0.3);
}
.logo_div {
  margin-left: 25px;
  padding: 2px;
}
.logo {
  /* max-height: 100%; */
  max-width: 110px;
}
.right_div {
  /* margin-left: 10px !important; */
  float: right;
  padding-right: 20px;
  /* margin-left: auto;
  margin-right: auto; */
}
.links {
  display: flex;
  list-style-type: none;
  gap: 2rem;
}
.links li a {
  /* color: #fc4958; */
  color: var(---primary-color-500);
  font-weight: var(---primary-font-weight);
  /* font-weight: 700; */
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  transition: 0.3s ease-in-out;
  /* cursor: pointer; */
}
.links li a :hover {
  color: #f9c74f;
  cursor: pointer;
}
.menu__icon {
  display: none;
}

/* Media Query...For Mobile View */
@media (max-width: 480px) {
  .logo {
    min-width: 100px;
    margin-top: 5px;
  }
  .right_div {
    display: none;
  }
  .menu__icon {
    display: block;
    font-size: 50px;
    color: #fc4958;
    position: relative;
    right: 0.5rem;
    top: -0.4rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }
  .topNav_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    /* border: 1px solid green; */
    height: 5rem;
  }
  .menu__icon:hover {
    cursor: pointer;
    color: #fc4958;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .mobile__menu__view {
    width: 100%;
    height: 15rem;
    top: 0;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: self-end;
    justify-content: flex-start;
    /* justify-content: flex-end;
    align-items: flex-end; */
    padding-top: 10px;
    z-index: 20;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
  }
  .nav__close_button {
    font-size: 40px;
    color: #fc4958;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    border: 2px solid rgba(0, 0, 0, 0.615);
    box-shadow: 2px 2px gray;
  }

  .mobile__menu__links {
    list-style-type: none;
    /* border: 2px solid green; */
    width: 100%;
    gap: 5px;
  }
  .mobile__menu__links > li {
    padding: 5px 0;
    /* background-color: gray; */
  }
  .mobile__menu__links > li > a {
    text-decoration: none;
    color: #ffffff;
    background-color: #fc4958;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 20px;
    padding: 5px 20px;
    border-radius: 5px;
  }
  .mobile__menu__links > li > a:hover {
    color: #fc4958;
    background-color: #ffffff;
  }
  .close__button__div {
    border-bottom: 2px solid black;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes rotateMenu {
    0% {
      transform: rotateX(-90deg);
    }
    70% {
      transform: rotateX(20deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
}
