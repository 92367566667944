.home_View {
  /* border: 2px solid green; */
  /* background-color: black; */
  color: #ffffff;
  /* background: rgb(240, 236, 236);
    border-radius: 30px;
    width: 150px;
    height: 150px; */
}
.lottie_Animation {
  height: 450px;
  width: 550px;
  border-radius: 10px solid #de535f;
  padding: 10px;
  position: relative;
  top: -50px;
  padding-left: 50px;
  /* left: 5%; */
  /* border: 2px solid yellow; */
}
.home_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  /* top: 0; */
  height: 100%;
  width: 100%;
}
.text_Container {
  /* position: absolute;
    right: 10%; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5rem;
  padding-right: 50px;
}
.text__container__tags {
  /* position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end; */
  /* align-items: end; */
  /* border: 1px solid green; */
}
.text__container__tags > h1 > span {
  color: var(---primary-color-500);
}
.text__container__tags > h2 > span {
  color: var(---primary-color-500);
}
.text__container__tags > h3 {
  color: var(---primary-color-500);
  font-size: 22px;
}
.home__button__div {
  display: flex;
  gap: 30px;
}
.know_more_button {
  padding: 1rem 1.5rem;
  font-size: 1.2rem;

  background: #e64b6a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6a82fb,
    #c63d59
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6a82fb,
    #fc5c7d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border: none;
  color: white;
  font-weight: 900;
  letter-spacing: 0.2rem;
  transition: 0.3s ease-in-out;
  /* cursor: pointer; */
  border-radius: 20px;
  z-index: 20;
}
.know_more_button:hover {
  background-color: #16382f;
  cursor: pointer;
}
.hero_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow: hidden;

  /* border: 2px solid green; */
}

@media (max-width: 480px) {
  .hero_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin-top: 1rem; */
  }
  .lottie_Animation {
    height: 225px;
    width: 250px;
    /* border-radius: 10px solid #de535f; */
    padding: 10px;
    position: relative;
    top: 20px;
  }

  .text_Container {
    padding: 10px;
  }
  .know_more_button {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
  .text__container__tags {
    /* align-items: flex-end; */
    /* display: block; */
    /* border: 2px solid rgb(255, 0, 221); */
  }
  .text__container__tags > h1 {
    font-size: 30px;
  }
  .text__container__tags > h2 {
    font-size: 24px;
  }
  .text__container__tags > h3 {
    font-size: 18px;
    display: block;
  }
  .home__button__div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* border: 2px solid rgb(0, 255, 81); */
  }
  .home__button__div > button {
    font-size: 13px;
    padding: 10px 10px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    border-radius: 5px;
    width: fit-content;
    padding: 10px 10px;
  }
}
