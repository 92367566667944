.about {
  /* border: 2px solid yellow;
  margin: 5vw;
  background: linear-gradient(to right, #fc4958, #e85d04, #fc4958);
  padding: 0.2rem;
  border-radius: 1.5rem;
  position: relative; */
  color: aliceblue;
  /* display: flex; */
}
.about_Container {
  z-index: 1;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  /* padding: 1.5rem; */
}
.profile_image_img {
  overflow: hidden;
  height: 35vh;
  width: 29vh;
  border-radius: 1.5rem;
  transition: 0.8s ease-in-out;
  border: 1px solid #00b4db;
  padding: 10px;
  margin-left: 1.5rem;
}
.profile_image_img:hover {
  transition: 0.8s ease-in-out;
  transform: scale(1.2);
}
.profile_image {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
  margin-top: 2%;
  /* border: 1px solid red; */
}

.text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.5rem;
}
.react_icons {
  height: 23vh;
  width: 18vh;
  transition: transform 0.7s ease-in-out;
}
.react_icons:hover {
  transform: rotate(360deg);
}
.icons_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.contact_me_Button {
  padding: 1rem 2rem;
  font-size: 1rem;
  /* background-color:#f42136; */
  background: #8e0e00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #655f57,
    #8e0e00
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #706a62,
    #8e0e00
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border: none;
  color: white;
  font-weight: 800;
  letter-spacing: 0.2rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 20px;
}
.contact_me_Button:hover {
  /* background-color: #fc4958; */
  background: #8e0e00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #dc9537,
    #d84a3a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #de8a1c,
    #e95747
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.my_project_Button {
  padding: 1rem 2rem;
  font-size: 1.4rem;
  background: #174853; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0083b0,
    #4a8897
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0083b0,
    #417b88
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #000000;
  border: none;
  font-weight: 800;
  letter-spacing: 0.2rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 20px;
}
.my_project_Button:hover {
  color: white;
  background: #00b4db; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0083b0,
    #00b4db
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0083b0,
    #00b4db
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/* .about_bottom_div{
  border: 2px solid red;
} */
.technical__skill__container {
  /* border: 2px solid green; */
  margin-left: 15px;
}
.technical__skill__container > h3 {
  color: #e95747;
  font-size: 30px;
  font-weight: 800;
}
.technical__skill_lst_container {
  padding: 10px;
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  gap: 5px;
}
.technical__skill_lst_container > p {
  font-weight: 800;
  font-weight: 50px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 5px 10px;
  border-radius: 20px;
  /* flex: 20%; */
}

.about_bottom_div {
  color: #de535f;
  width: 100%;
}
@media (max-width: 480px) {
  .about_Container {
    display: flex;
    flex-direction: column;
    top: 12%;
  }
  .icons_row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
  .react_icons {
    height: 16vh;
    width: 12vh;
    flex: 20%;
  }
  .my_project_Button {
    padding: 1rem 2rem;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  .about__bottom__div {
    display: none;
  }
  .about__bottom {
    color: #ffffff;
    border: 2px solid green;
    position: relative;
    /* bottom: 0; */
  }
}
