.project_card {
  /* flex: 1 0 21%; */
  /* flex: 1 0 calc(25% - 10px); */
  /* background-color: aquamarine; */
  /* border: 1px solid red; */
  /* min-width: 28rem; */
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 3px 3px rgb(185, 179, 179);
  box-shadow: 0 1.5em 2.5em -0.5em rgba(#000000, 0.1);
  transition: transform 0.45s ease, 0.45s ease;
  width: 20rem;
  /* transition: transform 0.07s ease-out; */
}
.project_card:hover {
  cursor: pointer;
  transform: translate(-10px, -10px);
}
.project_Image {
  /* width: 20rem; */
  width: 100%;
  object-fit: cover;
  padding-bottom: 10px;
  border-radius: 20px;
  /* border-bottom: 1px solid gray; */
}
.project__card__id__div {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #de535f;
}
.project__card__id__div > h3 {
  color: #2e3c40;
  font-size: 2.5em;
  font-weight: 600;
}

.project__card__github__icon {
  font-size: 40px;
}
.project__card__bottom > h4 {
  color: #627084;
  text-transform: uppercase;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin: 0 0 1.77em;
  transition: color 0.45s ease;
  margin-top: 10px;
}
.project__card__bottom > p {
  opacity: 9;
  color: black;
  font-weight: 600;
  line-height: 1.8;
  margin: 0 0 1.25em;
  transform: translateY(-1em);
  transition: opacity 0.45s ease, transform 0.5s ease-in;
  padding: 0 5px;
}

@media (max-width: 480px) {
  .project__card__id__div > h3 {
    font-size: 2em;
  }
  .project__card__bottom > h4 {
    font-size: 1.1rem;
  }
}
